<template>
  <div class="my-4">
    <div class="h5 mb-3">
      {{ text }}
    </div>
    <QuestionFormUpload
      v-if="props.type === 'File' && !props.review"
      :audit-address="auditAddress"
      :id="questionId"
      :files="value"
      :caption-options="captionOptions"
      @complete="uploadFile"
      @remove="removeFile"
      @download="downloadFile"
    />
    <FormInput
      v-else-if="props.type === 'File'"
      v-model="value"
      label="This is where a file upload would be requested"
    />
    <Component
      v-else
      :is="input"
      :id="questionId"
      :options="options"
      :disabled="disabled"
      label=""
      v-model="value"
      :step="props.type === 'Number' ? 0.01 : undefined"
      :float="props.type === 'Number' ? true : false"
    />
    <div v-if="props.review && props.isConditional" class="fs-6 text-secondary mt-4">
      <div>
        <i class="bi bi-info-circle"></i> This question is showing because you answered
        <b>{{ props.conditionAnswer }}</b> to "{{ conditionalQuestion }}"
      </div>
      <div class="mt-2" v-if="props.imageTag">
        This creates a non-compliance with severity
        <b>{{ nonComplianceDescription?.nonComplianceSeverity }}</b> with a description of "{{
          nonComplianceDescription?.listingDescripton
        }}"
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
<script setup>
import { computed } from 'vue';
import FormDate from './FormDate.vue';
import FormInput from './FormInput.vue';
import FormNumber from './FormNumber.vue';
import QuestionSelect from './QuestionSelect.vue';
import FormUpload from './FormUpload.vue';
import { useApi } from '../stores/api';
import { useAuditStore } from '../stores/audit';
import QuestionFormUpload from './QuestionFormUpload.vue';

const emit = defineEmits(['update']);
const props = defineProps({
  questionId: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
  },
  auditQuestionOptions: {
    type: Array,
    default: () => [],
  },
  answer: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  review: {
    type: Boolean,
    default: false,
  },
  imageTag: {
    type: String,
    default: '',
  },
  isConditional: {
    type: Boolean,
    default: false,
  },
  conditionQuestionId: {
    type: String,
    default: '',
  },
  conditionAnswer: {
    type: String,
    default: '',
  },
  nonComplianceDescriptions: {
    type: Array,
    default: () => [],
  },
  questions: {
    type: Array,
    default: () => [],
  },
});

const input = computed(
  () =>
    ({
      Date: FormDate,
      Text: FormInput,
      Number: FormNumber,
      Select: QuestionSelect,
      File: FormUpload,
    }[props.type] || 'div')
);

const audit = useAuditStore();
const auditAddress = computed(() => {
  return `${audit.onsite?.propertyAddress?.address1} ${audit.onsite?.propertyAddress?.postcode}`;
});

const options = computed(() => props.auditQuestionOptions.map((o) => o.option));

const value = computed({
  get() {
    if (props.type === 'File' && !props.review) return JSON.parse(props.answer || 'null');
    if (props.type === 'Number') return parseFloat(props.answer) || '';
    return props.answer;
  },
  set(val) {
    emit('update', val);
  },
});

const conditionalQuestion = computed(() => {
  const q = props.questions.find((x) => x.questionId == props.conditionQuestionId);
  return q?.text;
});

const nonComplianceDescription = computed(() => {
  const q = props.questions.find((x) => x.questionId == props.conditionQuestionId);
  return q?.nonComplianceDescriptions.find((x) => x.imageTag === props.imageTag);
});

const captionOptions = computed(() => {
  const q = props.questions.find((x) => x.questionId == props.questionId);
  return q?.captionOptions || [];
});

const uploadFile = (result) => {
  value.value = JSON.stringify({
    id: result.id || result.link,
    title: result.title || result.file?.name,
    href: result.href || result.link,
    caption: result.caption || '',
  });
};

const removeFile = () => {
  value.value = JSON.stringify({
    id: null,
    title: null,
    href: null,
    caption: value.value?.caption || '',
  });
};

const downloadFile = async (url) => {
  const api = useApi();
  const result = await api.getPresignedAuditUrl(url);
  if (result && typeof result === 'string') {
    window.location = result;
  }
};
</script>
