<template>
  <div v-if="audit.bespoke" class="d-flex flex-column">
    <div class="audit-section">
      <FormWrapper label="">
        <h5 class="mb-2">Bespoke Audit - {{ project.projectReference }}</h5>
        <div class="d-flex flex-column gap-2">
          <div class="result">
            <div class="result-label">Audit Status</div>
            <StatusBadge
              :status="statusStr"
              small
              :popover="noAccessReasons"
              :show-popover-icon="true"
            />
          </div>
          <div class="result">
            <div class="result-label">Overall Result</div>
            <StatusBadge
              :status="audit.bespoke.nonComplianceSeverity || audit.bespoke.onsiteOutcome"
              small
            />
          </div>
        </div>
      </FormWrapper>
    </div>

    <div class="audit-section d-flex flex-column gap-3">
      <FormInput id="bespokeAuditType" label="Audit Type" :model-value="audit.bespoke.bespokeAuditType" disabled />
      <FormInput id="auditor" label="Auditor" :model-value="audit.bespoke.auditor" disabled>
        <template #append>
          <button
            v-if="!isExternalAuditor"
            class="btn btn-outline-neutral text-primary"
            type="button"
            @click="audit.bespoke.auditor = null"
          >
            <i class="bi bi-pencil" />
          </button>
        </template>
      </FormInput>
      <FormInput
        v-if="audit.bespoke.lastAppointmentDateTime"
        label="Last Appointment"
        id="lastAppointmentDateTime"
        class="w-100"
        :model-value="
          formatISOStringDate(
            audit.bespoke.lastAppointmentDateTime,
            'EEE do MMM yyyy \'at\' hh:mmaaa'
          )
        "
        :popover="bookingHistory"
        disabled
      />
      <FormInput
        v-if="audit.bespoke.currentAppointmentDateTime"
        label="Next Visit On"
        id="currentAppointmentDateTime"
        class="w-100"
        :model-value="
          formatISOStringDate(
            audit.bespoke.currentAppointmentDateTime,
            'EEE do MMM yyyy \'at\' hh:mmaaa'
          )
        "
        disabled
      />
    </div>
    <div class="audit-section" :class="{ 'order-3': isComplete }">
      <FormWrapper label="Actions">
        <div class="d-flex flex-column gap-2">
          <button
            v-if="
              ((isComplete || inReview) && audit.bespoke.isNonCompliant) ||
              audit.bespoke.surveyReportS3Key
            "
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="onDownloadSurveyReport"
          >
            View Non-Compliance Report
          </button>
          <button
            v-if="audit.bespoke.isNonCompliant"
            class="btn btn-outline-primary w-100"
            type="button"
            @click.prevent="state.showingNonComplianceAnswers = true"
          >
            View Non-Compliance Answers
          </button>
          <button
            v-if="!audit.bespoke.currentAppointmentDateTime && !inReview"
            class="btn"
            :class="isComplete ? 'btn-outline-primary' : 'btn-primary'"
            type="button"
            @click.prevent="state.showingAppointment = true"
          >
            Book Appointment
          </button>
          <button
            v-if="isComplete || inReview"
            class="btn"
            :class="isComplete || inReview ? 'btn-outline-primary' : 'btn-primary'"
            type="button"
            @click.prevent="state.showingReview = true"
          >
            Edit & Regenerate Report
          </button>
          <button
            v-if="isComplete && isBespokeAuditAdmin"
            class="btn"
            :class="isComplete ? 'btn-outline-primary' : 'btn-primary'"
            type="button"
            @click.prevent="confirmOnAdminMoveToReview"
          >
            Move back to Review
          </button>
          <button
            v-if="!audit.bespoke.currentAppointmentDateTime && !audit.bespoke.lastAppointmentDateTime"
            class="btn btn-outline-danger"
            type="button"
            @click.prevent="state.showingNoAccessFlag = true"
          >
            Flag No Access
          </button>
          <RouterLink
            v-if="
              project.projectType?.toUpperCase() == 'ECO4' &&
              route.name !== 'project-property' &&
              !audit.bespoke.currentAppointmentDateTime &&
              !audit.bespoke.lastAppointmentDateTime
            "
            class="btn btn-outline-secondary"
            :to="{ name: 'project-property' }"
          >
            View Tenure Info
          </RouterLink>
          <button
            v-if="audit.bespoke.currentAppointmentDateTime && !hasPendingVisitLogs"
            class="btn btn-outline-primary w-100"
            type="button"
            @click="checkIn"
            :disabled="loading"
          >
            Check In
          </button>
          <button
            v-if="audit.bespoke.currentAppointmentDateTime && hasPendingVisitLogs"
            class="btn btn-outline-primary w-100"
            type="button"
            @click="checkOut"
            :disabled="loading"
          >
            Check Out
          </button>
          <button
            v-if="!isExternalAuditor && audit.bespoke.currentAppointmentDateTime"
            class="btn btn-outline-primary w-100"
            type="button"
            @click="state.showingAppointment = true"
          >
            Change / Cancel Appointment
          </button>
          <button
            v-if="audit.bespoke.currentAppointmentDateTime || inReview"
            class="btn btn-outline-primary w-100"
            type="button"
            @click.prevent="state.showingAddForm = true"
          >
            Add Form
          </button>
          <button
            v-if="
              audit.bespoke.currentAppointmentDateTime &&
              !hasPendingVisitLogs &&
              !inReview &&
              !isComplete
            "
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="state.showingReview = true"
          >
            Complete Audit
          </button>
          <button
            v-if="inReview"
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="confirmOnCompleteReview"
            :disabled="!isAuditReviewer"
          >
            Complete Review
          </button>
        </div>
      </FormWrapper>
    </div>

    <div
      v-if="!audit.bespoke.currentAppointmentDateTime && !audit.bespoke.lastAppointmentDateTime"
      class="audit-section"
      :class="{ 'order-5': isComplete }"
    >
      <div class="d-flex flex-column gap-3">
        <FormMultiSelect
          id="currentReasonsForAudit"
          label="Current Reasons for Audit"
          :options="appData.onsiteAuditReasonsTypes"
          :model-value="audit.bespoke.currentReasonsForAudit"
          @update:model-value="onUpdateAuditReasons"
        />
        <div>
          <FormTextarea
            id="notes"
            label="Booking Notes"
            rows="3"
            v-model="audit.bespoke.bookingNotes"
          />
          <button
            class="mt-2 w-100 btn btn-sm btn-outline-secondary"
            type="button "
            @click.prevent="onUpdateNotes()"
          >
            Save Notes
          </button>
        </div>
      </div>
    </div>

    <div v-if="forms.length" class="audit-section" :class="{ 'order-2': isComplete }">
      <FormWrapper label="Forms">
        <div class="d-flex flex-column gap-2">
          <AuditOnsiteFormThumb
            v-for="form of forms"
            :key="form.auditFormId"
            v-bind="form"
            @select="emit('form', form)"
          />
        </div>
      </FormWrapper>
    </div>
    <div
      v-if="appointmentBooked || isComplete || inReview"
      class="audit-section"
      :class="{ 'order-1': isComplete }"
    >
      <FormWrapper label="Outcomes">
        <div class="d-flex flex-column gap-2">
          <div v-for="outcome of outcomes" :key="outcome.title" class="result">
            <div class="result-label">{{ outcome.title }}</div>
            <StatusBadge
              :status="outcome.nonComplianceSeverity || outcome.outcome || undefined"
              :show-popover-icon="outcome.incompleteReason"
              :popover="outcome.incompleteReason"
              small
            />
          </div>
        </div>
      </FormWrapper>
    </div>

    <div
      v-if="(isComplete || inReview) && (audit.bespoke.observations || audit.bespoke.internalNotes)"
      class="audit-section"
      :class="{ 'order-4': isComplete }"
    >
      <div class="d-flex flex-column gap-3">
        <FormTextarea
          v-if="audit.bespoke.observations"
          id="notes"
          label="Observations"
          rows="3"
          :model-value="audit.bespoke.observations"
          disabled
        />
        <FormTextarea
          v-if="audit.bespoke.internalNotes"
          id="notes"
          label="Internal Notes"
          rows="3"
          :model-value="audit.bespoke.internalNotes"
          disabled
        />
      </div>
    </div>

    <div class="audit-section" :class="{ 'order-4': isComplete }">
      <FormInput
        v-if="audit.bespoke.updatedAt"
        label="Last Updated At"
        id="updatedAt"
        class="w-100 mb-4"
        :model-value="
          formatISOStringDate(audit.bespoke.updatedAt, 'EEE do MMM yyyy \'at\' hh:mmaaa')
        "
        disabled
      />
      <FormInput
        v-if="audit.bespoke.updatedBy"
        label="Last Updated By"
        id="updatedBy"
        class="w-100"
        :model-value="audit.bespoke.updatedBy"
        disabled
      />
    </div>

    <div
      class="audit-section"
      :class="{ 'order-5': isComplete }"
      v-if="isBespokeAuditDeleter"
    >
      <FormWrapper label="Danger Zone">
        <div class="d-flex flex-column gap-2">
          <button
            class="btn"
            :class="inReview ? 'btn-outline-danger' : 'btn-danger'"
            type="button"
            @click.prevent="confirmOnDeleteAudit"
          >
            Delete Audit
          </button>
        </div>
      </FormWrapper>
    </div>

    <Transition name="swipe-right">
      <div>
        <AuditOnsiteReview
          v-if="state.showingReview"
          :forms="forms"
          :current="audit.bespoke"
          @confirm="onConfirmComplete"
          @dismiss="state.showingReview = false"
        />
        <AuditOnsiteNoAccess
          v-else-if="state.showingNoAccessFlag"
          :audit="audit.bespoke"
          @confirm="onConfirmNoAccess"
          @dismiss="state.showingNoAccessFlag = false"
        />
        <AuditOnsiteAppointment
          v-else-if="state.showingAppointment"
          :datetime="audit.bespoke.currentAppointmentDateTime"
          @confirm="onConfirmAppointment"
          @dismiss="state.showingAppointment = false"
        />
        <AuditBespokeAddForm
          v-else-if="state.showingAddForm"
          @confirm="onAddForm"
          :project-id="audit.bespoke.retrofitProjectId"
          :bespoke-audit-id="audit.bespoke.bespokeAuditId"
          @dismiss="state.showingAddForm = false"
        />
        <AuditOnsiteNonComplianceAnswers
          v-else-if="state.showingNonComplianceAnswers"
          :measures="audit.bespoke.onsiteAuditMeasures"
          :forms="forms"
          @dismiss="state.showingNonComplianceAnswers = false"
        />
      </div>
    </Transition>
  </div>
  <Teleport to="body">
    <Modal ref="confirmModalEl" title="Complete Review" save-text="Complete Review">
      <template #form>Are you sure you want to Complete Review?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onCompleteReview()">
          Complete Review
        </button>
      </template>
    </Modal>
    <Modal ref="confirmReviewModalEl" title="Move back to Review" save-text="Move back to Review">
      <template #form>Are you sure you want to put this audit back into Review?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onAdminMoveToReview()">
          Move back to Review
        </button>
      </template>
    </Modal>
    <Modal ref="confirmDeleteAuditModalEl" title="Delete Audit" save-text="Delete Audit">
      <template #form
        >Are you sure you want to delete this Audit? This cannot be reversed and will remove
        everything!</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onDeleteAudit()">
          Delete Audit
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script>
export default {
  // For use with KeepAlive
  name: 'AuditOnsiteOverview',
};
</script>

<script setup>
import { computed, reactive, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAppStore } from '../stores/app';
import { useAuditStore } from '../stores/audit';
import { formatISOStringDate } from '../utilities/date';
import AuditOnsiteFormThumb from './AuditOnsiteFormThumb.vue';
import AuditOnsiteAppointment from './AuditOnsiteAppointment.vue';
import AuditOnsiteNoAccess from './AuditOnsiteNoAccess.vue';
import AuditOnsiteReview from './AuditOnsiteReview.vue';
import AuditOnsiteNonComplianceAnswers from './AuditOnsiteNonComplianceAnswers.vue';
import FormWrapper from './FormWrapper.vue';
import FormInput from './FormInput.vue';
import FormMultiSelect from './FormMultiSelect.vue';
import StatusBadge from './StatusBadge.vue';
import FormTextarea from './FormTextarea.vue';
import { useApi } from '../stores/api';
import AuditBespokeAddForm from './AuditBespokeAddForm.vue';
import Modal, { useModal } from '../components/Modal.vue';
import { useAuthStore } from '../stores/auth';
import { getLocation } from '../utilities/geolocation';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';

const emit = defineEmits([
  'complete',
  'update',
  'form',
  'addForm',
  'reviewComplete',
  'adminMoveToReview',
  'clearAudit',
  'deleteAudit',
]);

const props = defineProps({
  project: {
    required: true,
    type: Object,
  },
  lodgement: {
    type: Object,
    default: () => ({}),
  },
});

const appStore = useAppStore();
const { appData } = storeToRefs(appStore);
const { account } = storeToRefs(useAuthStore());
const audit = useAuditStore();
const route = useRoute();
const api = useApi();

const isAuditReviewer = computed(() => {
  return account.value?.roles?.indexOf('BespokeAuditReviewer') > -1;
});

const isExternalAuditor = computed(() => {
  return account.value?.roles?.includes('ExternalAuditor');
});

const { modal: confirmModal, el: confirmModalEl } = useModal();
const { modal: confirmReviewModal, el: confirmReviewModalEl } = useModal();
const { modal: confirmDeleteAuditModal, el: confirmDeleteAuditModalEl } = useModal();

const confirmOnCompleteReview = async () => {
  confirmModal.value.show();
};

const confirmOnAdminMoveToReview = async () => {
  confirmReviewModal.value.show();
};

const confirmOnDeleteAudit = async () => {
  confirmDeleteAuditModal.value.show();
};

const outcomes = computed(() => {
  if (!audit.bespoke) return [];
  const arr = [];
  if (!isComplete.value) {
    arr.push({
      title: 'Bespoke Audit',
      outcome: audit.bespoke.onsiteOutcome,
      nonComplianceSeverity: audit.bespoke.nonComplianceSeverity,
    });
  }
  audit.bespoke.onsiteAuditMeasures.forEach((measure) => {
    const form = audit.bespoke.forms.find((x) => x.measureId === measure.measureId);

    arr.push({
      title: measure.measureType,
      outcome: measure.outcome,
      nonComplianceSeverity: measure.nonComplianceSeverity,
      incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons.join('<br />') : null,
    });
  });
  if (audit.bespoke.onsiteAuditOtherOutcomes) {
    audit.bespoke.onsiteAuditOtherOutcomes.forEach((otherOutcome) => {
      const form = audit.bespoke.forms.find((x) => x.formOutcomeType === otherOutcome.outcomeType);

      arr.push({
        title: otherOutcome.outcomeType,
        outcome: otherOutcome.outcome,
        nonComplianceSeverity: otherOutcome.nonComplianceSeverity,
        incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons.join('<br />') : null,
      });
    });
  }
  arr.push({
    title: 'H&S Risk Assessment',
    outcome: audit.bespoke.healthSafetyRiskAssessmentOutcome,
  });
  return arr;
});

const state = reactive({
  showingAppointment: false,
  showingNoAccessFlag: false,
  showingNonComplianceAnswers: false,
  showingReview: false,
  showingAddForm: false,
});

const appointmentRequired = computed(() => audit.bespoke.status === 'Required');
const appointmentBooked = computed(() => audit.bespoke.status === 'Booked');
const isComplete = computed(() => audit.bespoke.status === 'Complete');
const inReview = computed(() => audit.bespoke.status === 'Review');
// const noAccess = computed(() => audit.bespoke.status === 'NoAccess');

const statusStr = computed(() => {
  return {
    Required: 'appointmentrequired',
    Booked: 'inprogress',
    Complete: 'complete',
    Review: 'review',
    Inprogress: 'inprogress',
    NoAccess: 'no access',
    CustomerCancelled: 'customer cancelled',
    TrustMarkCancelled: 'trustMark Cancelled',
  }[audit.bespoke.status || ''];
});

const noAccessReasons = computed(() => {
  return audit.bespoke.noAccessReasons?.join(`<br />`);
});

const forms = computed(() => {
  const isHealthAndSafetyForm = (form) => form.formStage === 'Health & Safety Risk Assessment';
  if (appointmentRequired.value) {
    return audit.bespoke.forms.filter(isHealthAndSafetyForm);
  }
  return audit.bespoke.forms;
});

const bookingHistoryPrefix = (customerCancelled, trustMarkCancelled) => {
  if (customerCancelled) {
    return 'Customer Cancelled';
  }
  if (trustMarkCancelled) {
    return 'TrustMark Cancelled';
  }
  return '';
};

const bookingHistory = computed(() => {
  return (
    'Booking History:<br />' +
    audit.bespoke.bookingHistory
      ?.map((x) => {
        return `${bookingHistoryPrefix(
          x.customerCancelledFlag,
          x.trustMarkCancelledFlag
        )}${formatISOStringDate(x.appointmentDateTime, "EEE do MMM yyyy 'at' hh:mmaaa")} - ${
          x.auditor
        }`;
      })
      .join('<br />')
  );
});

const onUpdateAuditReasons = (reasonsForAudit) => {
  emit('update', {
    updates: {
      reasonsForAudit,
    },
  });
};

const onConfirmAppointment = ({ date, time, customerCancelled, trustMarkCancelled }) => {
  let appointmentDate = date?.replace('00:00', time) || null;
  if (appointmentDate !== null) {
    const timezone = getTimezoneOffset();
    appointmentDate = zonedTimeToUtc(appointmentDate, timezone);
  }
  emit('update', {
    updates: {
      appointmentDate,
      noAccess: false,
      customerCancelled: customerCancelled || false,
      trustMarkCancelled: trustMarkCancelled || false,
      noAccessReasons: [],
    },
    next() {
      state.showingAppointment = false;
    },
  });
};

const onConfirmNoAccess = (noAccessReasons = []) => {
  emit('update', {
    updates: { noAccess: true, noAccessReasons },
    next() {
      state.showingNoAccessFlag = false;
    },
  });
};

const onUpdateNotes = () => {
  emit('update', {
    updates: { bookingNotes: audit.bespoke.bookingNotes },
  });
};

const onConfirmComplete = async (form) => {
  emit('complete', {
    form,
    next() {
      state.showingReview = false;
    },
  });
};

const onDownloadSurveyReport = async () => {
  const result = await api.getPresignedSurveyReportUrl(audit.bespoke.surveyReportS3Key);
  if (result && typeof result === 'string') {
    const link = document.createElement('a');
    link.href = result;
    link.setAttribute('download', `${audit.bespoke.surveyReportS3Key}.pdf`);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('type', 'application/pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const isBespokeAuditAdmin = computed(() => {
  return account.value?.roles?.includes('BespokeAuditAdmin');
});

const isBespokeAuditDeleter = computed(() => {
  return account.value?.roles?.includes('BespokeAuditDeleter');
});

const onDeleteAudit = async () => {
  confirmDeleteAuditModal.value?.hide();
  emit('deleteAudit');
};

const onAdminMoveToReview = async () => {
  emit('adminMoveToReview');
  confirmReviewModal.value?.hide();
};

const onCompleteReview = async (form) => {
  emit('reviewComplete', {
    form,
  });
  confirmModal.value?.hide();
};

const onAddForm = async (form) => {
  emit('addForm', {
    form,
    next() {
      state.showingAddForm = false;
    },
  });
};

const loading = ref(false);

const checkIn = async () => {
  if (!loading.value) {
    loading.value = true;
    const location = await getLocation();
    const result = await api.bespokeAuditVisitLogCheckIn({
      bespokeAuditId: audit.bespoke.bespokeAuditId,
      retrofitProjectId: audit.bespoke.retrofitProjectId,
      auditor: audit.bespoke.auditor,
      checkIn: new Date().toISOString(),
      lat: location.coords.latitude,
      long: location.coords.longitude,
    });
    if (result.isSuccess) {
      appStore.addNotification({
        type: 'success',
        message: 'Successfully checked in.',
        timeout: 5000,
      });
      await getProjectVisitLogs();
    }
    loading.value = false;
  }
};

const checkOut = async () => {
  if (!loading.value) {
    loading.value = true;
    const result = await api.bespokeAuditVisitLogCheckOut({
      bespokeAuditId: audit.bespoke.bespokeAuditId,
      retrofitProjectId: audit.bespoke.retrofitProjectId,
      auditor: audit.bespoke.auditor,
      checkOut: new Date().toISOString(),
      checkIn: new Date(projectVisitLogs.value.find((x) => !x.checkOut)?.checkIn).toISOString(),
    });
    if (result.isSuccess) {
      appStore.addNotification({
        type: 'success',
        message: 'Successfully checked out.',
        timeout: 5000,
      });
      await getProjectVisitLogs();
    }
    loading.value = false;
  }
};

const projectVisitLogs = ref([]);
const hasPendingVisitLogs = computed(() => {
  return projectVisitLogs.value.some((x) => !x.checkOut);
});

const getProjectVisitLogs = async () => {
  const result = await api.getBespokeAuditVisitLogsByProjectId(audit.bespoke.bespokeAuditId, audit.bespoke.retrofitProjectId);
  if (result.isSuccess) {
    projectVisitLogs.value = result.model;
  }
};

const link = computed(() => {
  if (props.lodgement?.lodgementId && props.lodgement?.retrofitProjectId === props.project?.retrofitProjectId) {
    return {
      name: 'lodgement-standalone-remediation',
      params: { projectId: props.project?.retrofitProjectId, lodgementId: props.lodgement?.lodgementId },
      query: { 'onsiteAudit': 'y' },
    }
  }
  return {
    name: 'project-remediation',
    params: { projectId: props.project?.retrofitProjectId },
    query: { 'onsiteAudit': 'y' },
  }
});

onMounted(getProjectVisitLogs);
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.result-label {
  font-weight: $form-label-font-weight;
  color: $gray-600;
  font-size: $form-label-font-size;
}
</style>
