<template>
  <div v-if="loaded" class="drawer-content">
    <div v-if="auditSelected" class="d-flex flex-column">
      <div class="audit-section">
        <a class="btn btn-outline-secondary btn-sm" @click="auditSelected = false">Back to list</a>
      </div>
    </div>
    <KeepAlive include="AuditBespokeOverview">
      <AuditBespokeList v-if="!auditSelected" :project="props.project" @select-audit="selectAudit" />
      <AuditBespokeAuditor
        v-else-if="!audit.bespoke.auditor"
        :project="props.project"
        @update="updateAudit"
      />
      <AuditOnsiteQuestionnaire
        v-else-if="activeForm"
        :project="project"
        :form="activeForm"
        :audit="audit.bespoke"
        @dismiss="activeForm = null"
        @complete="onFormComplete"
        @restart="onFormRestart"
      />
      <AuditBespokeOverview
        v-else
        :project="props.project"
        :lodgement="props.lodgement"
        @form="selectForm"
        @update="updateAudit"
        @add-form="addFormToAudit"
        @complete="moveToReview"
        @review-complete="reviewCompleteAudit"
        @admin-move-to-review="adminMoveToReview"
        @clear-audit="clearAudit"
        @delete-audit="deleteAudit"
      />
    </KeepAlive>
  </div>
  <div v-else class="drawer-content p-5 text-center">
    <span class="spinner-border" />
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { useApi } from '../stores/api';
import { useAuditStore } from '../stores/audit';
import { useProjectsStore } from '../stores/projects';
import AuditBespokeAuditor from './AuditBespokeAuditor.vue';
import AuditBespokeList from './AuditBespokeList.vue';
import AuditBespokeOverview from './AuditBespokeOverview.vue';
import AuditOnsiteQuestionnaire from './AuditOnsiteQuestionnaire.vue';
import { showNotification } from '../utilities/notification';

const loaded = ref(true);
const activeForm = ref(null);
const auditSelected = ref(false);
const selectForm = (form) => {
  activeForm.value = form;
};

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  lodgement: {
    type: Object,
    default: null,
  },
});

const api = useApi();
const audit = useAuditStore();

const updateAudit = async ({ updates, next }) => {
  const payload = {
    bespokeAuditType: audit.bespoke.bespokeAuditType,
    bespokeAuditId: audit.bespoke.bespokeAuditId,
    auditor: audit.bespoke.auditor,
    bookingNotes: audit.bespoke.bookingNotes,
    noAccess: audit.bespoke.noAccess,
    noAccessReasons: audit.bespoke.noAccessReasons,
    priority: audit.bespoke.priority || 'Normal',
    reasonsForAudit: audit.bespoke.reasonsForAudit,
    retrofitProjectId: props.project.retrofitProjectId,
  };
  try {
    let result;
    if (audit.bespoke.bespokeAuditId) {
      result = await api.updateBespokeAuditBooking(audit.bespoke.bespokeAuditId, props.project.retrofitProjectId, {
        ...payload,
        ...updates,
      });
    } else {
      result = await api.createBespokeAudit(props.project.retrofitProjectId, {
        ...payload,
        ...updates,
      });
    }
    if (result.isSuccess) {
      audit.bespoke = result.model;
    }
  } catch (error) {
    console.error(error);
  } finally {
    loaded.value = true;
  }
  if (next && typeof next === 'function') {
    next();
  }
};

const addFormToAudit = async ({ form, next }) => {
  const result = await api.addFormToBespokeAudit(
    audit.bespoke.bespokeAuditId,
    props.project.retrofitProjectId,
    form.auditQuestionSetId,
    form.measureId
  );
  audit.bespoke = result.model;
  if (next && typeof next === 'function') {
    next();
  }
};

const selectAudit = (a) => {
  audit.bespoke = a;
  auditSelected.value = true;
  if (a.bespokeAuditId) {
    initialise();
  }
};

const projectsStore = useProjectsStore();
const moveToReview = async ({ form, next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.moveBespokeAuditToReview(audit.bespoke.bespokeAuditId, projectId, form);
  if (result) {
    audit.bespoke = result.model;
    const fetchProject =
      audit.bespoke.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
  loaded.value = true;
};

const clearAudit = async () => {
  const projectId = props.project.retrofitProjectId;
  for (const form of audit.bespoke.forms) {
    const auditForm = await api.getAuditForm(projectId, form.auditFormId);
    if (auditForm.isSuccess) {
      for (const question of auditForm.model.questions.filter((x) => x.type === 'File')) {
        const id = JSON.parse(question.answer)?.id;
        if (id) {
          await api.deleteAuditFile(id);
        }
      }
    }
  }

  const result = await api.clearBespokeAudit(audit.bespoke.bespokeAuditId, projectId);
  if (result) {
    auditSelected.value = false;
  }
};

const deleteAudit = async () => {
  const projectId = props.project.retrofitProjectId;
  for (const form of audit.bespoke.forms) {
    const auditForm = await api.getAuditForm(projectId, form.auditFormId);
    if (auditForm.isSuccess) {
      for (const question of auditForm.model.questions.filter((x) => x.type === 'File')) {
        const id = JSON.parse(question.answer)?.id;
        if (id) {
          await api.deleteAuditFile(id);
        }
      }
    }
  }

  const result = await api.deleteBespokeAudit(audit.bespoke.bespokeAuditId, projectId);
  if (result) {
    auditSelected.value = false;
  }
};

const adminMoveToReview = async () => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.adminMoveBespokeAuditToReview(audit.bespoke.bespokeAuditId, projectId);
  showNotification(result.message);
  if (result) {
    audit.bespoke = result.model;
    const fetchProject =
      audit.bespoke.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
  }
};

const reviewCompleteAudit = async ({ next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.reviewCompleteBespokeAudit(audit.bespoke.bespokeAuditId, projectId);
  if (result) {
    audit.bespoke = result.model;
    const fetchProject =
      audit.bespoke.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
};

const initialise = async () => {
  loaded.value = false;
  const result = await api.silently().getBespokeAudit(audit.bespoke.bespokeAuditId, props.project.retrofitProjectId);
  if (result) {
    audit.bespoke = result.model;
    loaded.value = true;
  }
};

const onFormComplete = () => {
  activeForm.value = null;
  initialise();
};

const onFormRestart = async () => {
  const result = await api.restartBespokeAuditForm(
    audit.bespoke.bespokeAuditId,
    audit.bespoke.retrofitProjectId,
    activeForm.value.auditFormId
  );
  if (result && result.model) {
    activeForm.value = null;
    audit.bespoke = result.model;
  }
};
</script>
