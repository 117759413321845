<template>
  <div v-if="loaded" class="drawer-content">
    <div class="d-flex flex-column">
      <div class="audit-section">
        <div v-if="bespokeAudits.length === 0">No existing bespoke audits</div>
        <div v-else>
          <h5 class="mb-2">Bespoke Audits - {{ project.projectReference }}</h5>
          <div v-for="audit in bespokeAudits" :key="audit.auditDate" class="card mb-2 clickable" @click="selectAudit(audit)">
            <div class="card-body">
              <div>Audit Type: {{ audit.bespokeAuditType }}</div>
              <div>Audit Date: {{ formatDate(audit.completedDateTime || audit.lastAppointmentDateTime || audit.appointmentBookedDateTime) || 'Not set' }}</div>
              <div>Status: <StatusBadge :status="audit.status" /></div>
            </div>
          </div>
        </div>
        <button
          v-if="!isExternalAuditor"
          class="btn btn-primary mt-2"
          @click="emit('select-audit', { forms: [] })">
          Create New
        </button>
      </div>
    </div>
  </div>
  <div v-else class="drawer-content p-5 text-center">
    <span class="spinner-border" />
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useApi } from '../stores/api';
import { useAuthStore } from '../stores/auth';
import StatusBadge from './StatusBadge.vue';
import { formatDate } from '../utilities/date';

const bespokeAudits = ref([]);
const authStore = useAuthStore();

const isExternalAuditor = computed(() => {
  return authStore.account?.roles?.includes('ExternalAuditor');
});

const emit = defineEmits(['select-audit']);

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  lodgement: {
    type: Object,
    default: null,
  },
});

const api = useApi();

const selectAudit = (a) => {
  emit('select-audit', a);
};

// Initialisation
const loaded = ref(false);
const initialise = async () => {
  const result = await api.getBespokeAuditsByProjectId(props.project.retrofitProjectId);
  if (result.isSuccess) {
    loaded.value = true;
    bespokeAudits.value = result.model.map((ba) => ({
      forms: [],
      ...ba,
    }));
  }
};
onBeforeMount(initialise);
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #f0f0f0;
}
</style>
