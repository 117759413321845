<template>
  <div v-if="audit.desktop" class="d-flex flex-column">
    <div class="audit-section">
      <FormWrapper label="">
        <h5 class="mb-2">Desktop Audit - {{ project.projectReference }}</h5>
        <div class="d-flex flex-column gap-2">
          <div class="result" v-if="project.remediationStatus">
            <router-link :to="link"  class="result-label">Remediation Status</router-link>
            <StatusBadge :status="project.remediationStatus" small />
          </div>
          <div class="result" v-if="project.desktopStatus">
            <div class="result-label">Desktop Status</div>
            <StatusBadge :status="project.desktopStatus" small />
          </div>
          <div class="result">
            <div class="result-label">Status</div>
            <StatusBadge
              :status="statusStr"
              small
              :popover="noAccessReasons"
              :show-popover-icon="true"
            />
          </div>
          <div class="result">
            <div class="result-label">Overall Result</div>
            <StatusBadge
              :status="audit.desktop.nonComplianceSeverity || audit.desktop.onsiteOutcome"
              small
            />
          </div>
        </div>
      </FormWrapper>
    </div>

    <div class="audit-section d-flex flex-column gap-3">
      <FormInput id="auditor" label="Auditor" :model-value="audit.desktop.auditor" disabled>
        <template #append>
          <button
            v-if="!isExternalAuditor"
            class="btn btn-outline-neutral text-primary"
            type="button"
            @click="audit.desktop.auditor = null"
          >
            <i class="bi bi-pencil" />
          </button>
        </template>
      </FormInput>
    </div>
    <div class="audit-section" :class="{ 'order-3': isComplete }">
      <FormWrapper label="Actions">
        <div class="d-flex flex-column gap-2">
          <button
            v-if="
              ((isComplete || inReview) && audit.desktop.isNonCompliant) ||
              audit.desktop.surveyReportS3Key
            "
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="onDownloadSurveyReport"
          >
            View Desktop Non-Compliance Report
          </button>
          <button
            v-if="audit.desktop.isNonCompliant"
            class="btn btn-outline-primary w-100"
            type="button"
            @click.prevent="state.showingNonComplianceAnswers = true"
          >
            View Non-Compliance Answers
          </button>
          <button
            v-if="isComplete || inReview"
            class="btn"
            :class="isComplete || inReview ? 'btn-outline-primary' : 'btn-primary'"
            type="button"
            @click.prevent="state.showingReview = true"
          >
            Edit & Regenerate Report
          </button>
          <button
            v-if="isComplete && isOnsiteAuditAdmin"
            class="btn"
            :class="isComplete ? 'btn-outline-primary' : 'btn-primary'"
            type="button"
            @click.prevent="confirmOnAdminMoveToReview"
          >
            Move back to Review
          </button>
          <RouterLink
            v-if="project.lodgementOfWorkStyle === 'Project' && route.name !== 'project-documents'"
            class="btn btn-outline-secondary"
            :to="{ name: 'project-documents' }"
          >
            View Documents
          </RouterLink>
          <button
            v-if="inReview"
            class="btn btn-outline-primary w-100"
            type="button"
            @click.prevent="state.showingAddForm = true"
          >
            Add Form
          </button>
          <button
            v-if="!inReview && !isComplete"
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="state.showingReview = true"
          >
            Complete Audit
          </button>
          <button
            v-if="inReview"
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="confirmOnCompleteReview"
            :disabled="!isAuditReviewer"
          >
            Complete Review
          </button>
        </div>
      </FormWrapper>
    </div>

    <div v-if="forms.length" class="audit-section" :class="{ 'order-2': isComplete }">
      <FormWrapper label="Forms">
        <div class="d-flex flex-column gap-2">
          <AuditOnsiteFormThumb
            v-for="form in forms"
            :key="form.auditFormId"
            v-bind="form"
            @select="emit('form', form)"
          />
        </div>
      </FormWrapper>
    </div>
    <div v-if="isComplete || inReview" class="audit-section" :class="{ 'order-1': isComplete }">
      <FormWrapper label="Outcomes">
        <div class="d-flex flex-column gap-2">
          <div v-for="outcome in outcomes" :key="outcome.title" class="result">
            <div class="result-label">{{ outcome.title }}</div>
            <StatusBadge
              :status="outcome.nonComplianceSeverity || outcome.outcome || undefined"
              :show-popover-icon="outcome.incompleteReason"
              :popover="outcome.incompleteReason"
              small
            />
          </div>
        </div>
      </FormWrapper>
    </div>

    <div
      v-if="(isComplete || inReview) && (audit.desktop.observations || audit.desktop.internalNotes)"
      class="audit-section"
      :class="{ 'order-4': isComplete }"
    >
      <div class="d-flex flex-column gap-3">
        <FormTextarea
          v-if="audit.desktop.observations"
          id="notes"
          label="Observations"
          rows="3"
          :model-value="audit.desktop.observations"
          disabled
        />
        <FormTextarea
          v-if="audit.desktop.internalNotes"
          id="notes"
          label="Internal Notes"
          rows="3"
          :model-value="audit.desktop.internalNotes"
          disabled
        />
      </div>
    </div>

    <div class="audit-section" :class="{ 'order-4': isComplete }">
      <FormInput
        v-if="audit.desktop.updatedAt"
        label="Last Updated At"
        id="updatedAt"
        class="w-100 mb-4"
        :model-value="
          formatISOStringDate(audit.desktop.updatedAt, 'EEE do MMM yyyy \'at\' hh:mmaaa')
        "
        disabled
      />
      <FormInput
        v-if="audit.desktop.updatedBy"
        label="Last Updated By"
        id="updatedBy"
        class="w-100"
        :model-value="audit.desktop.updatedBy"
        disabled
      />
    </div>

    <div
      class="audit-section"
      :class="{ 'order-5': isComplete }"
      v-if="isDesktopAuditDeleter"
    >
      <FormWrapper label="Danger Zone">
        <div class="d-flex flex-column gap-2">
          <button
            class="btn"
            :class="inReview ? 'btn-outline-danger' : 'btn-danger'"
            type="button"
            @click.prevent="confirmOnClearAudit"
          >
            Clear Audit
          </button>
        </div>
      </FormWrapper>
    </div>

    <Transition name="swipe-right">
      <div>
        <AuditDesktopReview
          v-if="state.showingReview"
          :forms="forms"
          :current="audit.desktop"
          @confirm="onConfirmComplete"
          @dismiss="state.showingReview = false"
        />
        <AuditOnsiteAddForm
          v-else-if="state.showingAddForm"
          @confirm="onAddForm"
          :project-id="audit.desktop.retrofitProjectId"
          @dismiss="state.showingAddForm = false"
        />
        <AuditOnsiteNonComplianceAnswers
          v-else-if="state.showingNonComplianceAnswers"
          :measures="audit.desktop.desktopAuditMeasures"
          :forms="forms"
          @dismiss="state.showingNonComplianceAnswers = false"
        />
      </div>
    </Transition>
  </div>
  <Teleport to="body">
    <Modal ref="confirmModalEl" title="Complete Review" save-text="Complete Review">
      <template #form>Are you sure you want to Complete Review?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onCompleteReview()">
          Complete Review
        </button>
      </template>
    </Modal>
    <Modal ref="confirmReviewModalEl" title="Move back to Review" save-text="Move back to Review">
      <template #form>Are you sure you want to put this audit back into Review?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onAdminMoveToReview()">
          Move back to Review
        </button>
      </template>
    </Modal>
    <Modal ref="confirmClearAuditModalEl" title="Clear Audit" save-text="Clear Audit">
      <template #form
        >Are you sure you want to clear this Audit? This cannot be reversed and will remove
        everything!</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onClearAudit()">
          Clear Audit
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script>
export default {
  // For use with KeepAlive
  name: 'AuditDesktopOverview',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuditStore } from '../stores/audit';
import AuditOnsiteFormThumb from './AuditOnsiteFormThumb.vue';
import AuditDesktopReview from './AuditDesktopReview.vue';
import AuditOnsiteNonComplianceAnswers from './AuditOnsiteNonComplianceAnswers.vue';
import FormWrapper from './FormWrapper.vue';
import FormInput from './FormInput.vue';
import StatusBadge from './StatusBadge.vue';
import FormTextarea from './FormTextarea.vue';
import { useApi } from '../stores/api';
import AuditOnsiteAddForm from './AuditOnsiteAddForm.vue';
import Modal, { useModal } from '../components/Modal.vue';
import { useAuthStore } from '../stores/auth';
import { formatISOStringDate } from '../utilities/date';

const emit = defineEmits(['complete', 'form', 'addForm', 'reviewComplete', 'adminMoveToReview']);

const props = defineProps({
  project: {
    required: true,
    type: Object,
  },
  lodgement: {
    type: Object,
    default: () => ({}),
  },
});

const { account } = storeToRefs(useAuthStore());
const audit = useAuditStore();
const route = useRoute();
const api = useApi();

const isAuditReviewer = computed(() => {
  return account.value?.roles?.indexOf('DesktopAuditReviewer') > -1;
});

const isDesktopAuditDeleter = computed(() => {
  return account.value?.roles?.includes('DesktopAuditDeleter');
});

const isExternalAuditor = computed(() => {
  return account.value?.roles?.includes('ExternalAuditor');
});

const { modal: confirmModal, el: confirmModalEl } = useModal();
const { modal: confirmReviewModal, el: confirmReviewModalEl } = useModal();
const { modal: confirmClearAuditModal, el: confirmClearAuditModalEl } = useModal();

const confirmOnCompleteReview = async () => {
  confirmModal.value.show();
};

const confirmOnAdminMoveToReview = async () => {
  confirmReviewModal.value.show();
};

const confirmOnClearAudit = async () => {
  confirmClearAuditModal.value.show();
};

const outcomes = computed(() => {
  if (!audit.desktop) return [];
  const arr = [];
  if (!isComplete.value) {
    arr.push({
      title: 'Desktop Audit',
      outcome: audit.desktop.onsiteOutcome,
      nonComplianceSeverity: audit.desktop.nonComplianceSeverity,
    });
  }
  audit.desktop.desktopAuditMeasures.forEach((measure) => {
    const form = audit.desktop.forms.find((x) => x.measureId === measure.measureId);
    arr.push({
      title: measure.measureType,
      outcome: measure.outcome,
      nonComplianceSeverity: measure.nonComplianceSeverity,
      incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons?.join('<br />') : null,
    });
  });
  if (audit.desktop.desktopAuditOtherOutcomes) {
    audit.desktop.desktopAuditOtherOutcomes.forEach((otherOutcome) => {
      const form = audit.desktop.forms.find((x) => x.formOutcomeType === otherOutcome.outcomeType);
      arr.push({
        title: otherOutcome.outcomeType,
        outcome: otherOutcome.outcome,
        nonComplianceSeverity: otherOutcome.nonComplianceSeverity,
        incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons?.join('<br />') : null,
      });
    });
  }
  return arr;
});

const state = reactive({
  showingNonComplianceAnswers: false,
  showingReview: false,
  showingAddForm: false,
});

const isComplete = computed(() => audit.desktop.status === 'Complete');
const inReview = computed(() => audit.desktop.status === 'Review');
// const noAccess = computed(() => audit.desktop.status === 'NoAccess');

const statusStr = computed(() => {
  return {
    Required: 'appointmentrequired',
    Booked: 'inprogress',
    Complete: 'complete',
    Review: 'review',
    Inprogress: 'inprogress',
    NoAccess: 'no access',
    CustomerCancelled: 'customer cancelled',
    TrustMarkCancelled: 'trustMark Cancelled',
  }[audit.desktop.status || ''];
});

const forms = computed(() => {
  return audit.desktop.forms;
});

const onConfirmComplete = async (form) => {
  emit('complete', {
    form,
    next() {
      state.showingReview = false;
    },
  });
};

const onDownloadSurveyReport = async () => {
  const result = await api.getPresignedSurveyReportUrl(audit.desktop.surveyReportS3Key);
  if (result && typeof result === 'string') {
    const link = document.createElement('a');
    link.href = result;
    link.setAttribute('download', `${audit.desktop.surveyReportS3Key}.pdf`);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('type', 'application/pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const isOnsiteAuditAdmin = computed(() => {
  return account.value?.roles?.includes('OnsiteAuditAdmin');
});

const onClearAudit = async () => {
  confirmClearAuditModal.value?.hide();
  emit('clearAudit');
};

const onAdminMoveToReview = async () => {
  emit('adminMoveToReview');
  confirmReviewModal.value?.hide();
};

const onCompleteReview = async (form) => {
  emit('reviewComplete', {
    form,
  });
  confirmModal.value?.hide();
};

const onAddForm = async (form) => {
  emit('addForm', {
    form,
    next() {
      state.showingAddForm = false;
    },
  });
};

const link = computed(() => {
  if (props.lodgement?.lodgementId && props.lodgement?.retrofitProjectId === props.project?.retrofitProjectId) {
    return {
      name: 'lodgement-standalone-remediation',
      params: { projectId: props.project?.retrofitProjectId, lodgementId: props.lodgement?.lodgementId },
      query: { 'desktopAudit': 'y' },
    }
  }
  return {
    name: 'project-remediation',
    params: { projectId: props.project?.retrofitProjectId },
    query: { 'desktopAudit': 'y' },
  }
});
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.result-label {
  font-weight: $form-label-font-weight;
  color: $gray-600;
  font-size: $form-label-font-size;
}
</style>
