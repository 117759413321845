<template>
  <PageHeader title="Dashboard">
    <div class="d-flex align-items-center justify-content-end">
      <FormSelect
        v-if="isTM"
        id="select-audit-type"
        v-model="auditType"
        class="filter-audit-type me-2"
        @update:model-value="searchAll"
      >
        <option :value="null">All</option>
        <option value="Onsite">Onsite</option>
        <option v-if="!isExternalAuditor" value="Desktop">Desktop</option>
        <option v-if="isBespokeAuditor" value="Bespoke">Bespoke</option>
      </FormSelect>
      <FormSelect
        v-if="isTM"
        id="select-auditor"
        v-model="auditor"
        class="filter-auditor"
        @update:model-value="searchAll"
      >
        <option v-if="filterToMe" :value="filterToMe.value">{{ filterToMe.label }}</option>
        <option v-if="!isExternalAuditor" value="">Select All</option>
        <optgroup v-if="auditors?.length && !isExternalAuditor" label="Select an Auditor">
          <option v-for="auditor of auditors" :key="auditor.value" :value="auditor.value">
            {{ auditor.label }}
          </option>
        </optgroup>
      </FormSelect>
    </div>
  </PageHeader>
  <div class="dashboard">
    <div
      v-if="isRA || isRC || isOnsiteAuditor || isLodgementNonTMViewer"
      class="dashboard-entities"
    >
      <div v-if="isRA" class="dashboard-section">
        <h5 class="mb-1">Assessments</h5>
        <div class="description mb-3">
          See your recently viewed assessments or create a new one for a property.
        </div>
        <div class="top-level mb-3">
          <span class="count">{{ assessmentsStore.searchParams.totalItems }}</span>
          {{ plural(assessmentsStore.searchParams.totalItems, 'assessment') }}
          available.
        </div>
        <RecentlyViewed
          v-if="assessmentsStore.recentlyViewed?.length"
          :items="assessmentsStore.recentlyViewed"
          title="Assessments Recently Viewed"
          class="mb-3"
        />
        <Component :is="isInactiveLicence ? 'button' : 'RouterLink'" class="btn btn-primary" :to="{ name: 'assessment-new' }" :disabled="isInactiveLicence">New Assessment</Component>
      </div>

      <div v-if="isRC" class="dashboard-section">
        <h5 class="mb-1">Projects</h5>
        <div class="description mb-3">Start a new project or carry on from where you left off.</div>
        <div class="top-level mb-3">
          <span class="count">{{ projectsStore.searchParams.totalItems }}</span>
          {{ plural(projectsStore.searchParams.totalItems, 'project') }}
          available.
        </div>
        <RecentlyViewed
          v-if="projectsStore.recentlyViewed?.length"
          :items="projectsStore.recentlyViewed"
          title="Projects Recently Viewed"
          class="mb-3"
        />
        <Component :is="isInactiveLicence ? 'button' : 'RouterLink'" class="btn btn-primary" :to="{ name: 'project-new' }" :disabled="isInactiveLicence">New Project</Component>
      </div>

      <div v-if="isLodgementNonTMViewer" class="dashboard-section">
        <h5 class="mb-1">Lodgements</h5>
        <div class="description mb-3">Add a lodgement to a project.</div>
        <div class="top-level mb-3">
          <span class="count">{{ lodgementsStore.searchParams.totalItems }}</span>
          {{ plural(lodgementsStore.searchParams.totalItems, 'lodgement') }}
          available.
        </div>
        <RecentlyViewed
          v-if="lodgementsStore.recentlyViewed?.length"
          :items="lodgementsStore.recentlyViewed"
          title="Lodgements Recently Viewed"
          class="mb-3"
        />
        <Component :is="isInactiveLicence ? 'button' : 'RouterLink'" class="btn btn-primary" :to="{ name: 'lodgement-new' }" :disabled="isInactiveLicence">New Lodgement</Component>
      </div>
      <div v-if="isLodgementNonTMViewer" class="dashboard-section">
        <h5 class="mb-1">Legacy Lodgements</h5>
        <div class="description mb-3">
          Access your legacy TrustMark lodgements through accounts linked to your TMLN.
        </div>
        <RouterLink class="btn btn-primary" :to="{ name: 'lodgements-legacy' }"
          >View Legacy Data Warehouse</RouterLink
        >
      </div>

      <div v-if="isOnsiteAuditor && auditType !== 'Desktop' && auditType !== 'Bespoke'" class="dashboard-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="mb-1">Onsite Audits</h5>
            <div class="description mb-3">
              <span class="count">{{ onsiteAuditPaging.totalItems }}</span>
              {{ plural(onsiteAuditPaging.totalItems, 'Onsite Audit') }} {{ onsiteAuditStatus === 'Review' || '' ? 'awaiting review' : onsiteAuditStatus === 'Complete' ? 'complete' : 'in progress'}}.
            </div>
          </div>
          <a class="btn btn-outline-secondary" data-bs-toggle="collapse" href="#onsiteAuditCollapse" role="button" aria-expanded="false" aria-controls="onsiteAuditCollapse">
            <i class="bi-arrows-collapse" />
          </a>
        </div>
        <div id="onsiteAuditCollapse" class="collapse show">
          <div class="mb-3">
            <div class="badge rounded-pill bg-secondary" @click.prevent="onOnsiteStatusChange('Inprogress')" :class="{'bg-success': onsiteAuditStatus === 'Inprogress'}">In Progress</div>
            <div class="badge rounded-pill bg-secondary mx-2" @click.prevent="onOnsiteStatusChange('Review')" :class="{'bg-success': onsiteAuditStatus === 'Review'}">Review</div>
            <div class="badge rounded-pill bg-secondary" @click.prevent="onOnsiteStatusChange('Complete')" :class="{'bg-success': onsiteAuditStatus === 'Complete'}">Complete</div>
          </div>
          <OnsiteAuditThumb
            v-for="item of onsiteAuditsInReview"
            v-bind="item"
            :key="item.appointmentId"
          />
          <Pagination
            :page="onsiteAuditPaging.page + 1"
            :total-pages="onsiteAuditPaging.totalPages"
            :page-size="onsiteAuditPaging.pageSize"
            :total-items="onsiteAuditPaging.totalItems"
            @change="onOnsiteAuditPageChange"
          />
        </div>
      </div>

      <div v-if="!isExternalAuditor" class="dashboard-section">
        <div v-if="isOnsiteAuditor && auditType !== 'Onsite' && auditType !== 'Bespoke'">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="mb-1">Desktop Audits</h5>
              <div class="description mb-3">
            <span class="count">{{ desktopAuditPaging.totalItems }}</span>
            {{ plural(desktopAuditPaging.totalItems, 'Desktop Audit') }} {{ desktopAuditStatus === 'Review' || '' ? 'awaiting review' : desktopAuditStatus === 'Complete' ? 'complete' : 'in progress'}}.
              </div>
            </div>
            <a class="btn btn-outline-secondary" data-bs-toggle="collapse" href="#desktopAuditCollapse" role="button" aria-expanded="false" aria-controls="desktopAuditCollapse">
              <i class="bi-arrows-collapse" />
            </a>
          </div>
          <div id="desktopAuditCollapse" class="collapse show">
            <div class="mb-3">
              <div class="badge rounded-pill bg-secondary" @click.prevent="onDesktopStatusChange('Inprogress')" :class="{'bg-success': desktopAuditStatus === 'Inprogress'}">In Progress</div>
              <div class="badge rounded-pill bg-secondary mx-2" @click.prevent="onDesktopStatusChange('Review')" :class="{'bg-success': desktopAuditStatus === 'Review'}">Review</div>
              <div class="badge rounded-pill bg-secondary" @click.prevent="onDesktopStatusChange('Complete')" :class="{'bg-success': desktopAuditStatus === 'Complete'}">Complete</div>
            </div>
            <OnsiteAuditThumb
              v-for="item of desktopAuditsInReview"
              v-bind="item"
              :key="item.appointmentId"
              :is-desktop="true"
            />
            <Pagination
              :page="desktopAuditPaging.page + 1"
              :total-pages="desktopAuditPaging.totalPages"
              :page-size="desktopAuditPaging.pageSize"
              :total-items="desktopAuditPaging.totalItems"
              @change="onDesktopAuditPageChange"
            />
          </div>
        </div>
      </div>      

      <div v-if="isBespokeAuditor && auditType !== 'Onsite' && auditType !== 'Desktop'" class="dashboard-section">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="mb-1">Bespoke Audits</h5>
            <div class="description mb-3">
              <span class="count">{{ bespokeAuditPaging.totalItems }}</span>
              {{ plural(bespokeAuditPaging.totalItems, 'Bespoke Audit') }} {{ bespokeAuditStatus === 'Review' || '' ? 'awaiting review' : bespokeAuditStatus === 'Complete' ? 'complete' : 'in progress'}}.
            </div>
          </div>
          <a class="btn btn-outline-secondary" data-bs-toggle="collapse" href="#bespokeAuditCollapse" role="button" aria-expanded="false" aria-controls="bespokeAuditCollapse">
            <i class="bi-arrows-collapse" />
          </a>
        </div>
        <div id="bespokeAuditCollapse" class="collapse show">
          <div class="mb-3">
            <div class="badge rounded-pill bg-secondary" @click.prevent="onBespokeStatusChange('Inprogress')" :class="{'bg-success': bespokeAuditStatus === 'Inprogress'}">In Progress</div>
            <div class="badge rounded-pill bg-secondary mx-2" @click.prevent="onBespokeStatusChange('Review')" :class="{'bg-success': bespokeAuditStatus === 'Review'}">Review</div>
            <div class="badge rounded-pill bg-secondary" @click.prevent="onBespokeStatusChange('Complete')" :class="{'bg-success': bespokeAuditStatus === 'Complete'}">Complete</div>
          </div>
          <BespokeAuditThumb
            v-for="item of bespokeAuditsInReview"
            v-bind="item"
            :key="item.appointmentId"
          />
          <Pagination
            :page="bespokeAuditPaging.page + 1"
            :total-pages="bespokeAuditPaging.totalPages"
            :page-size="bespokeAuditPaging.pageSize"
            :total-items="bespokeAuditPaging.totalItems"
            @change="onBespokeAuditPageChange"
          />
        </div>
      </div>
    </div>

    <div v-if="hasRemediationAccess" class="dashboard-remediation">
      <div class="d-flex justify-content-evenly">
        <div class="dashboard-section w-50 me-2" style="padding-right:0">
          <h5 class="mb-1">Evidence Review Todo</h5>
          <div class="description mb-3">See the evidence that is required to review.</div>
          <div class="top-level mb-3">
            <span class="count">{{ evidenceLength }}</span>
            files to review.
          </div>
          <template v-if="evidenceList?.length">
            <EvidenceThumb
              v-for="e of evidenceList"
              :key="e.remediationEvidenceId"
              class="mb-2"
              v-bind="e"
            />
            <Pagination
              :page="remediationEvidencePaging.page + 1"
              :total-pages="remediationEvidencePaging.totalPages"
              :page-size="remediationEvidencePaging.pageSize"
              :total-items="remediationEvidencePaging.totalItems"
              @change="onRemediationEvidencePageChange"
            />
          </template>
        </div>
        <div class="dashboard-section w-50" style="padding-left:0">
          <h5 class="mb-1">Unread Conversations</h5>
          <div class="description mb-3">See all unread remediation responses.</div>
          <div class="top-level mb-3">
            <span class="count">{{ conversationLength }}</span>
            messages to read.
          </div>
          <template v-if="conversationList?.length">
            <ConversationThumb
              v-for="(e, i) in conversationList"
              :key="i"
              class="mb-2"
              v-bind="e"
            />
            <Pagination
              :page="remediationConversationPaging.page + 1"
              :total-pages="remediationConversationPaging.totalPages"
              :page-size="remediationConversationPaging.pageSize"
              :total-items="remediationConversationPaging.totalItems"
              @change="onRemediationConversationPageChange"
            />
          </template>
        </div>
      </div>
    </div>

    <div v-if="!isExternalAuditor" class="dashboard-remediation">
      <div class="dashboard-section">
        <h5 class="mb-1">Remediation</h5>
        <div class="description mb-3">See projects and lodgements currently under remediation.</div>
        <div class="top-level mb-3">
          <span class="count">{{ remediationCount }}</span>
          remediations to review.
        </div>
        <FormCheckbox id="includeNoAccessCount" v-model="includeNoAccessCount" label="Include No Access" class="mb-2" @update:modelValue="searchRemediations" />
        <div class="mb-4 d-flex flex-wrap gap-2">
          <div class="badge rounded-pill bg-danger">Escalated to SP ({{ escalatedToSPCount }})</div>
          <div class="badge rounded-pill bg-danger">Escalated to Assurance ({{ escalatedToAssuranceCount }})</div>
          <br />
          <div class="badge rounded-pill bg-danger">Overdue ({{ overdueCount }})</div>
          <div class="badge rounded-pill bg-danger">Cat 1 ({{ cat1Count }})</div>
          <div v-if="isTM" class="badge rounded-pill bg-warning">Major ({{ majCount }})</div>
          <div v-if="isTM" class="badge rounded-pill bg-light text-muted">
            Minor ({{ minCount }})
          </div>
          <div v-if="!isTM" class="badge rounded-pill bg-warning">
            {{ ncCount }} Non-Compliance
          </div>
          <div v-if="includeNoAccessCount" class="badge rounded-pill bg-light text-muted">No Access ({{ noAccessCount }})</div>
        </div>
        <template v-if="remediationGroups?.length">
          <div v-for="group of remediationGroups" :key="group.severity" class="mb-4">
            <h6 class="mb-2">{{ mapSeverity(group.severity) }}</h6>
            <RemediationThumb
              v-for="(entry, i) of group.entries"
              :key="entry.id"
              :class="{ 'mb-2': i < group.entries.length - 1 }"
              v-bind="entry"
            />
          </div>
          <Pagination
            :page="remediationPaging.page + 1"
            :total-pages="remediationPaging.totalPages"
            :page-size="remediationPaging.pageSize"
            :total-items="remediationPaging.totalItems"
            @change="onRemediationPageChange"
          />
        </template>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <Modal
      ref="confirmModalEl"
      title="Accept Terms"
      save-text="Accept Terms"
      backdrop="static"
      custom-class="modal-lg"
    >
      <template #form>
        These rules have been established to protect both you and us and to help us both keep out of
        trouble and as such may be subject to change as required.
        <h5 class="mt-4">Definitions</h5>
        <p class="mt-2">
          "We / Us" means TrustMark (2005) Ltd<br />
          "You" means any party other than TrustMark (2005) Ltd<br />
          "Lodgement" means work or services recorded and submitted into the Data Warehouse<br />
          "Data Warehouse" means the TrustMark operated platform hosted at
          <a>data-hub.org.uk</a> that may be also be accessed via the TrustMark Business Portal on
          <a>trustmark.org.uk</a>
        </p>
        <h5 class="mt-4">Rules</h5>
        <p class="mt-2">
          a. You will obtain consent of the property owner or any data subject about which details
          are provided into the Data Warehouse.<br />
          b. You are responsible for the quality of the data you provide entered.<br />
          c. You will not provide any data that may harm or offend any other people.<br />
          d. You will not upload data relating to children or special categories of data (those
          relating to your racial or ethnic origin; political opinions; religious or philosophical
          beliefs; trade union membership; sex life or orientation; genetic data; biometric data;
          disability; or data relating to any criminal convictions or offences).<br />
          e. You will not misuse the Data Warehouse; examples of misuse may include:<br />
          <span class="ps-4"
            >I. Using the data for purposes other than the conduct of work for your consumer, such
            as sales or marketing purposes.</span
          ><br />
          <span class="ps-4">II. Sharing log in details or passwords.</span><br />
          <span class="ps-4">III. Attempting to 'hack' or access systems or data.</span><br />
          f. Upon the completion of work recorded or 'Lodged' into the Data Warehouse we will
          generate and email a Lodgement Certificate to the Property Owner and provide a link to our
          Privacy Notice so that your customer can understand how their data will be used.<br />
          g. You grant us permission to access the data provided including files or photos uploaded
          for the purposes detailed in our Privacy Notice. This encompasses both your organisational
          data and work-related information. Furthermore, this data may be disclosed to government
          entities as required.<br />
          h. You will not imitate or present yourself as TrustMark and will not recreate or copy our
          services.<br />
          i. You accept that we may contact you, your customer, the registered business conducting
          work or Scheme Provider in relation to quality assurance activity relating to the
          information provided into the data warehouse.<br />
          j. In the event of a personal data breach with a high risk of adversely affecting your
          customers you will notify them immediately. You will also notify us of any personal data
          breach without undue delay at data@trustmark.org.uk<br /><br />

          If you have any comments or suggestions for how we can improve these terms, please contact
          <a href="mailto:data@trustmark.org.uk" target="_blank" rel="noopener noreferrer"
            >data@trustmark.org.uk</a
          >
        </p>
      </template>
      <template #footer>
        <RouterLink :to="{ name: 'logout' }" class="btn btn-secondary">Close & Logout</RouterLink>
        <button type="button" class="btn btn-primary" @click.prevent="acceptTAndCs">
          Accept Terms
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { parseISO } from 'date-fns';
import { useApi } from '../stores/api';
import { useAppStore } from '../stores/app';
import { useAccountStore } from '../stores/account';
import { useAssessmentsStore } from '../stores/assessments';
import { useLodgementsStore } from '../stores/lodgements';
import { useProjectsStore } from '../stores/projects';
import { useAuthStore } from '../stores/auth';
import { mapSeverity } from '../utilities/compliance';
import PageHeader from '@/components/PageHeader.vue';
import RecentlyViewed from '@/components/RecentlyViewed.vue';
import RemediationThumb from '@/components/RemediationThumb.vue';
import OnsiteAuditThumb from '@/components/OnsiteAuditThumb.vue';
import BespokeAuditThumb from '@/components/BespokeAuditThumb.vue';
import EvidenceThumb from '@/components/EvidenceThumb.vue';
import ConversationThumb from '@/components/ConversationThumb.vue';
import Modal, { useModal } from '@/components/Modal.vue';
import Pagination from '@/components/Pagination.vue';
import FormSelect from '@/components/FormSelect.vue';
import { storeToRefs } from 'pinia';
import FormCheckbox from '@/components/FormCheckbox.vue';

useHead({ title: 'Dashboard • TrustMark | Retrofit Portal' });

const projectsStore = useProjectsStore();
const assessmentsStore = useAssessmentsStore();
const lodgementsStore = useLodgementsStore();
const appState = useAppStore();
const api = useApi();
const authStore = useAuthStore();
const accountStore = useAccountStore();
const route = useRoute();
const { isInactiveLicence } = storeToRefs(authStore);

const isRA = computed(() => {
  return appState.appData?.flags.isRetroAssessor;
});

const isRC = computed(() => {
  return appState.appData?.flags.isRetrofitCoordinator;
});

const isTM = computed(() => {
  return appState.appData?.flags.isTrustmark;
});

const isOnsiteAuditor = computed(() => {
  return authStore.account?.roles?.indexOf('OnsiteAuditViewer') > -1;
});

const isBespokeAuditor = computed(() => {
  return authStore.account?.roles?.indexOf('BespokeAuditViewer') > -1;
});

const isExternalAuditor = computed(() => {
  return authStore.account?.roles?.includes('ExternalAuditor');
});

const hasRemediationAccess = computed(() => {
  return isExternalAuditor.value ? false : isTM.value;
});

const isLodgementNonTMViewer = computed(() => {
  return (
    !isTM.value &&
    (authStore.account?.roles?.indexOf('RetrofitSubmissionofData') > -1 ||
      authStore.account?.roles?.indexOf('RetrofitProjectViewer') > -1)
  );
});

const plural = (n, word, append = 's') => `${word}${n === 1 ? '' : append}`;

const remediationCount = ref(0);
const remediationGroups = ref([]);
const onsiteAuditsInReview = ref([]);
const bespokeAuditsInReview = ref([]);
const desktopAuditsInReview = ref([]);
const evidenceLength = ref(0);
const evidenceList = ref([]);
const conversationLength = ref(0);
const conversationList = ref([]);
const includeNoAccessCount = ref(false);

const searchAll = async () => {
  accountStore.defaultFilters.auditType = auditType.value;
  remediationSearchProps.value.pageParameters.number = 0;
  remediationEvidenceSearchProps.value.pageParameters.number = 0;
  remediationConversationSearchProps.value.pageParameters.number = 0;
  onsiteAuditSearchProps.value.pageParameters.number = 0;
  bespokeAuditSearchProps.value.pageParameters.number = 0;
  await Promise.all([
    searchRemediations(),
    searchOnsiteAudits(),
    searchDesktopAudits(),
    searchBespokeAudits(),
    searchRemediationEvidences(),
    searchRemediationConversations(),
  ]);
};

const remediationSearchProps = ref({
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'updatedAt',
    direction: 'ascending',
  },
});
const remediationPaging = ref({});

const onRemediationPageChange = async (index) => {
  remediationSearchProps.value.pageParameters.number = index - 1;
  await searchRemediations();
};

const searchRemediations = async () => {
  const searchProps = { ...remediationSearchProps.value };
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  }
  if (auditType.value) {
    searchProps.hasOnsiteAudit = auditType.value === 'Onsite' || null;
    searchProps.hasDesktopAudit = auditType.value === 'Desktop' || null;
    searchProps.hasBespokeAudit = auditType.value === 'Bespoke' || null;
  }
  searchProps.includeNoAccess = includeNoAccessCount.value;
  searchProps.sort.item = 'escalationDateTime';
  searchProps.sort.direction = 'ascending';
  const result = await api.dashboardSearchRemediation(searchProps);
  if (result && result.searchResult && result.searchResult.results) {
    const results = result.searchResult.results.map((r) => ({
      id: r.remediationId,
      projectId: r.retrofitProjectId,
      lodgementId: r.lodgementId,
      projectReference: r.projectReference,
      severity: r.currentNonComplianceSeverity,
      status: r.status,
      escalationDateTime: r.escalatedBy ? undefined : parseISO(r.escalationDateTime),
      address1: r.address1,
      postcode: r.postcode,
      desktopStatus: r.desktopStatus,
      onsiteStatus: r.onsiteStatus,
      bespokeStatus: r.bespokeStatus,
    }));

    remediationCount.value = result.searchResult.totalItems;
    remediationGroups.value = results
      .reduce(
        (groups, result) => {
          const group = groups.find((g) => g.severity === result.severity || g.severity === result.status);
          group?.entries.push(result);
          return groups;
        },
        [
          { severity: 'Escalated to Assurance', entries: [] },
          { severity: 'Escalated to SP', entries: [] },
          { severity: 'Overdue', entries: [] },
          { severity: 'Cat 1', entries: [] },
          { severity: 'Non-Compliance (Major)', entries: [] },
          { severity: 'Non-Compliance (Minor)', entries: [] },
          { severity: 'Non-Compliance', entries: [] },
          { severity: 'No Access', entries: [] },
        ]
      )
      .filter((group) => group.entries.length);

    escalatedToSPCount.value = result.counts.escalatedToSP;
    noAccessCount.value = result.counts.noAccess;
    escalatedToAssuranceCount.value = result.counts.escalatedToAssurance;
    overdueCount.value = result.counts.overdue;
    cat1Count.value = result.counts.cat1;
    majCount.value = result.counts.ncMajor;
    minCount.value = result.counts.ncMinor;
    ncCount.value = result.counts.ncMajor + result.counts.ncMinor;

    remediationPaging.value = {
      totalItems: result.searchResult.totalItems,
      page: result.searchResult.page,
      pageSize: result.searchResult.pageSize,
      totalPages: result.searchResult.totalPages,
    };
  }
};

const remediationEvidenceSearchProps = ref({
  status: 'Requires Review',
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'createdAt',
    direction: 'asc',
  },
});
const remediationEvidencePaging = ref({});

const onRemediationEvidencePageChange = async (index) => {
  remediationEvidenceSearchProps.value.pageParameters.number = index - 1;
  await searchRemediationEvidences();
};

const searchRemediationEvidences = async () => {
  const searchProps = { ...remediationEvidenceSearchProps.value };
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  }
  if (auditType.value) {
    searchProps.auditType = auditType.value;
  }

  const result = await api.searchRemediationEvidenceAdvance(searchProps);

  if (result && result.results) {
    evidenceList.value = result.results;
    evidenceLength.value = result.totalItems;
    remediationEvidencePaging.value = {
      totalItems: result.totalItems,
      page: result.page,
      pageSize: result.pageSize,
      totalPages: result.totalPages,
    };
  }
};

const remediationConversationSearchProps = ref({
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'createdAt',
    direction: 'asc',
  },
});
const remediationConversationPaging = ref({});

const onRemediationConversationPageChange = async (index) => {
  remediationConversationSearchProps.value.pageParameters.number = index - 1;
  await searchRemediationConversations();
};

const searchRemediationConversations = async () => {
  const searchProps = { ...remediationConversationSearchProps.value };
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  }
  if (auditType.value) {
    searchProps.auditType = auditType.value;
  }

  const result = await api.searchRemediationConversationAdvance(searchProps);

  if (result && result.results) {
    conversationList.value = result.results;
    conversationLength.value = result.totalItems;
    remediationConversationPaging.value = {
      totalItems: result.totalItems,
      page: result.page,
      pageSize: result.pageSize,
      totalPages: result.totalPages,
    };
  }
};

const onsiteAuditSearchProps = ref({
  status: 'Review',
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'updatedAt',
    direction: 'descending',
  },
});
const onsiteAuditPaging = ref({});
const onsiteAuditStatus = ref('Review');

const onOnsiteAuditPageChange = async (index) => {
  onsiteAuditSearchProps.value.pageParameters.number = index - 1;
  await searchOnsiteAudits();
};

const onOnsiteStatusChange = async (status) => {
  if (onsiteAuditStatus.value === status) {
    onsiteAuditStatus.value = '';
  } else {
    onsiteAuditStatus.value = status;
  }
  await searchOnsiteAudits();
};

const searchOnsiteAudits = async () => {
  const searchProps = JSON.parse(JSON.stringify({ ...onsiteAuditSearchProps.value }));
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  } else {
    delete searchProps.auditor;
  }
  if (onsiteAuditStatus.value && onsiteAuditStatus.value !== '') {
    searchProps.status = onsiteAuditStatus.value;
  }

  if (onsiteAuditSearchProps.value.status !== searchProps.status || onsiteAuditSearchProps.value.auditor !== searchProps.auditor) {
    searchProps.pageParameters.number = 0;
  }

  const result = await api.searchOnsiteAudits(searchProps);

  if (result && result.results) {
    onsiteAuditsInReview.value = result.results;
    onsiteAuditPaging.value = {
      totalItems: result.totalItems,
      page: result.page,
      pageSize: result.pageSize,
      totalPages: result.totalPages,
    };
    onsiteAuditSearchProps.value = searchProps;
  }
};

const bespokeAuditSearchProps = ref({
  status: 'Review',
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'updatedAt',
    direction: 'descending',
  },
});
const bespokeAuditPaging = ref({});
const bespokeAuditStatus = ref('Review');

const onBespokeAuditPageChange = async (index) => {
  bespokeAuditSearchProps.value.pageParameters.number = index - 1;
  await searchBespokeAudits();
};

const onBespokeStatusChange = async (status) => {
  if (bespokeAuditStatus.value === status) {
    bespokeAuditStatus.value = '';
  } else {
    bespokeAuditStatus.value = status;
  }
  await searchBespokeAudits();
};

const searchBespokeAudits = async () => {
  const searchProps = JSON.parse(JSON.stringify({ ...bespokeAuditSearchProps.value }));
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  } else {
    delete searchProps.auditor;
  }
  if (bespokeAuditStatus.value && bespokeAuditStatus.value !== '') {
    searchProps.status = bespokeAuditStatus.value;
  }

  if (bespokeAuditSearchProps.value.status !== searchProps.status || bespokeAuditSearchProps.value.auditor !== searchProps.auditor) {
    searchProps.pageParameters.number = 0;
  }

  const result = await api.searchBespokeAudits(searchProps);

  if (result && result.results) {
    bespokeAuditsInReview.value = result.results;
    bespokeAuditPaging.value = {
      totalItems: result.totalItems,
      page: result.page,
      pageSize: result.pageSize,
      totalPages: result.totalPages,
    };
    bespokeAuditSearchProps.value = searchProps;
  }
};

const desktopAuditSearchProps = ref({
  status: 'Review',
  pageParameters: {
    number: 0,
    size: 12,
  },
  sort: {
    item: 'updatedAt',
    direction: 'descending',
  },
});
const desktopAuditPaging = ref({});
const desktopAuditStatus = ref('Review');

const onDesktopAuditPageChange = async (index) => {
  desktopAuditSearchProps.value.pageParameters.number = index - 1;
  await searchDesktopAudits();
};

const onDesktopStatusChange = async (status) => {
  if (desktopAuditStatus.value === status) {
    desktopAuditStatus.value = '';
  } else {
    desktopAuditStatus.value = status;
  }
  await searchDesktopAudits();
};

const searchDesktopAudits = async () => {
  const searchProps = JSON.parse(JSON.stringify({ ...desktopAuditSearchProps.value }));
  if (auditor.value && auditor.value !== '') {
    searchProps.auditor = auditor.value;
  } else {
    delete searchProps.auditor;
  }
  if (desktopAuditStatus.value && desktopAuditStatus.value !== '') {
    searchProps.status = desktopAuditStatus.value;
  }

  if (desktopAuditSearchProps.value.status !== searchProps.status || desktopAuditSearchProps.value.auditor !== searchProps.auditor) {
    searchProps.pageParameters.number = 0;
  }

  const result = await api.searchDesktopAudits(searchProps);

  if (result && result.results) {
    desktopAuditsInReview.value = result.results;
    desktopAuditPaging.value = {
      totalItems: result.totalItems,
      page: result.page,
      pageSize: result.pageSize,
      totalPages: result.totalPages,
    };
    desktopAuditSearchProps.value = searchProps;
  }
};

const auditor = ref(authStore.account?.accountName);
const auditType = ref(accountStore.defaultFilters?.auditType || null);
const auditorsRaw = ref(null);
const filterToMe = computed(() => {
  if (!auditorsRaw.value) return null;
  return auditorsRaw.value
    .filter((auditor) => auditor.accountId === authStore.account?.accountName)
    .map((auditor) => ({
      label: 'Filter to Me',
      value: auditor.accountId,
    }))?.[0];
});
const auditors = computed(() => {
  if (!auditorsRaw.value) return null;
  return auditorsRaw.value
    .filter((account) => account.accountId !== authStore.account?.accountName)
    .filter((account) => auditType.value === 'Onsite' ? account.roles.includes('OnsiteAuditor') : auditType.value === 'Desktop' ? account.roles.includes('DesktopAuditor') : true)
    .sort((a, b) => a.accountId.localeCompare(b.accountId))
    .map((auditor) => ({
      label: auditor.accountId,
      value: auditor.accountId,
    }));
});

const overdueCount = ref(0);
const escalatedToSPCount = ref(0);
const escalatedToAssuranceCount = ref(0);
const cat1Count = ref(0);
const majCount = ref(0);
const minCount = ref(0);
const ncCount = ref(0);
const noAccessCount = ref(0);

const { modal: confirmModal, el: confirmModalEl } = useModal();

const acceptTAndCs = async () => {
  const result = await api.acceptTerms();
  if (result.isSuccess) {
    authStore.account.acceptedRetrofitTAndCs = true;
  }
  confirmModal.value?.hide();
};

onMounted(async () => {
  const fetchItems = (search, store) => async () => {
    const result = await search({
      term: '',
      page: 1,
      sort: {
        item: 'updatedAt',
        direction: 'desc',
      },
    });
    if (result) {
      store.setList(result.results);
      store.searchParams = {
        term: '',
        pageSize: result.pageSize,
        page: result.page,
        totalPages: result.totalPages,
        totalItems: result.totalItems,
      };
    } else {
      store.setList([]);
    }
  };
  const requests = [];
  if (isRA.value) {
    requests.push(fetchItems(api.searchAssessments, assessmentsStore));
  }
  if (isRC.value) {
    requests.push(fetchItems(api.searchProjects, projectsStore));
    requests.push(fetchItems(api.searchLodgements, lodgementsStore));
  }
  if (isTM.value) {
    if (isOnsiteAuditor.value) {
      requests.push(async () => {
        await searchOnsiteAudits();
        await searchDesktopAudits();
      });
    }
    if (isBespokeAuditor.value) {
      requests.push(async () => {
        await searchBespokeAudits();
      });
    }

    requests.push(async () => {
      await searchRemediationEvidences();
    });

    requests.push(async () => {
      await searchRemediationConversations();
    });
  }

  requests.push(async () => {
    await searchRemediations();
  });

  if (isTM.value) {
    requests.push(async () => {
      const result = await api.getOnsiteAuditors();
      const desktopResult = await api.getDesktopAuditors();
      const bespokeResult = await api.getBespokeAuditors();
      if (result) {
        auditorsRaw.value = [...auditorsRaw.value || [], ...result.model];
      }
      if (desktopResult) {
        auditorsRaw.value = [...auditorsRaw.value || [], ...desktopResult.model];
      }
      if (bespokeResult) {
        auditorsRaw.value = [...auditorsRaw.value || [], ...bespokeResult.model];
      }

      auditorsRaw.value = auditorsRaw.value?.filter((auditor, index, self) =>
        index === self.findIndex((t) => t.accountId === auditor.accountId)
      );
    });
  }

  requests.forEach((f) => f());

  if (
    route.query.showRetrofitTAndCs === 'y' &&
    authStore.account?.acceptedRetrofitTAndCs !== true
  ) {
    confirmModal.value?.show();
  }
});
</script>

<style lang="scss" scoped>
.dashboard-entities {
  border-bottom: 1px solid $border-color;
}

@include media-breakpoint-up(xl) {
  .dashboard {
    display: flex;
  }
  .dashboard-entities {
    border-bottom: none;
    width: 33.333333%;
    flex-grow: 1;
  }
  .dashboard-remediation {
    border-left: 1px solid $border-color;
    height: calc(100vh - $navbar-height - $page-header-height);
    overflow-y: auto;
  }

  .dashboard-remediation {
    width: 33.333333%;
  }
}

.dashboard-section {
  padding: 1.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}
.description {
  color: $secondary;
  font-size: 0.875em;
  line-height: 1.25;
}

.top-level {
  color: $secondary;
}

.count {
  font-size: 2rem;
  color: $primary;
}

.filter-auditor {
  width: 400px;
}
</style>
