<template>
  <RouterLink
    class="bespoke-audit-thumb card text-decoration-none text-body"
    :to="link"
  >
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-start mb-1">
        <h5 class="mb-0">{{ projectReference }}</h5>
        <StatusBadge :status="status" small />
      </div>
      <div class="text-secondary fs-6">
        <div class="mb-1 d-flex justify-content-between align-items-center">
          <div v-if="propertyAddress">
            <i class="bi bi-geo-alt me-2" />{{ propertyAddress?.address1 }},
            {{ propertyAddress?.postcode }}
          </div>
        </div>
        <div class="d-flex">
          <i class="bi bi-clipboard-check me-2" /><span class="text-break">{{ auditor }}</span>
        </div>
      </div>
    </div>
    <div class="card-footer text-secondary fs-xxs">
      {{ updatedAt }}
    </div>
  </RouterLink>
</template>

<script setup>
import { formatDistanceToNow } from 'date-fns';
import { computed } from 'vue';
import StatusBadge from './StatusBadge.vue';

const props = defineProps({
  appointmentId: { type: String, required: true },
  retrofitProjectId: { type: String, required: true },
  lodgementOfWorkStyle: { type: String, default: undefined },
  lodgementId: { type: String, default: undefined },
  auditor: { type: String, required: true },
  status: { type: String, required: true },
  updatedAt: { type: String, default: undefined },
  propertyAddress: { type: Object, default: undefined },
  projectReference: { type: String, default: undefined },
});

const updatedAt = computed(() => {
  if (props.updatedAt)
    return `Last updated ${formatDistanceToNow(new Date(props.updatedAt), { addSuffix: true })}`;
  return 'Not yet updated';
});

const link = computed(() => {
  if (props.lodgementOfWorkStyle === 'Standalone') {
    return {
      name: 'lodgement-standalone',
      params: { projectId: props.retrofitProjectId, lodgementId: props.lodgementId },
      query: { bespokeAudit: 'y'},
    };
  }
  return {
    name: 'project',
    params: { projectId: props.retrofitProjectId },
    query: { bespokeAudit: 'y'},
  };
});
</script>
